import * as Sentry from "@sentry/nextjs";
import { Component } from "react";

/**
 * ErrorBoundary Component
 *
 * A React error boundary component that catches JavaScript errors anywhere in its child component tree,
 * logs those errors to Sentry, and displays a fallback UI instead of crashing the whole app.
 *
 * Features:
 * - Catches errors in the component tree below it
 * - Reports errors to Sentry for monitoring
 * - Displays a fallback UI when an error occurs
 *
 * Usage:
 * ```jsx
 * <ErrorBoundary>
 *   <YourComponent />
 * </ErrorBoundary>
 * ```
 *
 * @component
 * @example
 * // Wrap your application or specific components
 * import ErrorBoundary from './components/ErrorBoundary';
 *
 * function App() {
 *   return (
 *     <ErrorBoundary>
 *       <MainContent />
 *     </ErrorBoundary>
 *   );
 * }
 */

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      Sentry.withScope((scope) => {
        scope.setLevel("fatal");
        scope.setTag("component", "Something went wrong");
        Sentry.captureException(this.state.error);
      });

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
            padding: "20px",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              color: "#666",
              marginBottom: "10px",
              padding: "8px",
              backgroundColor: "#f8f8f8",
              borderRadius: "4px",
            }}
          >
            <p style={{ margin: "0" }}>
              Error details: {this.state.error?.message || "Unknown error occurred"}
            </p>
          </div>
          <h1>Something went wrong.</h1>
          <p>
            The error has been logged and the DM2Buy team has been notified. We'll fix this issue as
            soon as possible.
          </p>
          <p>Please try again later or contact support if the problem persists.</p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
