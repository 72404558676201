import "../styles/globals.css";
import * as Sentry from "@sentry/nextjs";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Head from "next/head";
import { useEffect, useState } from "react";
import { AppWrapper } from "../contexts/StoreDataContext";

function MyApp({ Component, pageProps }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      }),
  );

  useEffect(() => {
    // Initialize Sentry only on the client side
    if (typeof window !== "undefined") {
      Sentry.init({
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || process.env.SENTRY_DSN,
        tracesSampleRate: 1.0,
        environment: process.env.NODE_ENV,
        enabled: true,
        debug: process.env.NODE_ENV === "development",
      });
    }
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    if (typeof window !== "undefined") {
      (async () => {
        const { BrowserAgent } = await import("@newrelic/browser-agent/loaders/browser-agent");
        const options = {
          init: {
            privacy: { cookies_enabled: true },
            ajax: { deny_list: ["bam.nr-data.net"] },
            session_trace: { enabled: false },
          },
          info: {
            beacon: "bam.nr-data.net",
            errorBeacon: "bam.nr-data.net",
            licenseKey: "NRJS-8967c1c089def300e56",
            applicationID: "594561068",
            sa: 1,
          },
          loader_config: {
            accountID: "4390026",
            trustKey: "4390026",
            agentID: "594561068",
            licenseKey: "NRJS-8967c1c089def300e56",
            applicationID: "594561068",
          },
        };
        new BrowserAgent(options);
      })();
    }
  }, []);

  const GTM_ID = "GTM-NSVMFWJ9";
  //console.log("pageProps?.storeData", pageProps?.storeData);

  return (
    <AppWrapper sharedState={pageProps?.storeData}>
      <Head>
        <script id="gtm" strategy="afterInteractive">
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');
          `}
        </script>

        {pageProps?.storeData?.facebookPixel && (
          <>
            <script
              id="fb"
              dangerouslySetInnerHTML={{
                __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${pageProps.storeData.facebookPixel}');
            fbq('track', 'PageView');`,
              }}
            />
            <noscript
              dangerouslySetInnerHTML={{
                __html: `
            <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=${pageProps.storeData.facebookPixel}&ev=PageView&noscript=1"/>`,
              }}
            />
          </>
        )}
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <Component {...pageProps} />
          <ReactQueryDevtools initialIsOpen={false} />
        </Hydrate>
      </QueryClientProvider>
    </AppWrapper>
  );
}

export default MyApp;
