import { createContext, useContext } from "react";
import ErrorBoundary from "../components/ErrorBoundary";

const StoreContext = createContext({});
StoreContext.display = "StoreContext";

export function AppWrapper({ sharedState, children }) {
  // let sharedState = {/* whatever you want */}

  return (
    <ErrorBoundary>
      <StoreContext.Provider value={sharedState}>{children}</StoreContext.Provider>
    </ErrorBoundary>
  );
}

export function useStoreContext() {
  return useContext(StoreContext);
}
